import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ConnexionComponent } from './pages/connexion/connexion.component';
import { MdpOublieComponent } from './pages/mdp-oublie/mdp-oublie.component';
import { InitMdpComponent } from './pages/init-mdp/init-mdp.component';
import { ConnexionSsoComponent } from './pages/connexion-sso/connexion-sso.component';
import { LoginActivateService } from './services/login-activate.service';
import { ChoixReseauComponent } from './pages/choix-reseau/choix-reseau.component';
import { ConnectComponent } from './pages/connect/connect.component';


const routes: Routes = [
  //{ path: '', redirectTo: 'connexion', pathMatch: 'full' },

  { path: '', loadChildren: () => import('./home/home.module').then( m => m.HomeModule)},
  { path: 'connexion', component : ConnexionComponent},
 
  { path: 'mdp-oublie', component : MdpOublieComponent},
  { path: 'init-mdp/:token/:code', component : InitMdpComponent},
  { path: 'connexion-sso/:token', component : ConnexionSsoComponent},
  { path: 'connect', component: ConnectComponent },
  //{ path: 'choix-reseau', component: ChoixReseauComponent, canActivate: [LoginActivateService] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
