import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-init-mdp',
  templateUrl: './init-mdp.component.html',
  styleUrls: ['./init-mdp.component.scss']
})
export class InitMdpComponent implements OnInit {

  token;
  code;
  password;
  password_confirmation;
  erreur;

  constructor(private route: ActivatedRoute, private authService: AuthService, private router: Router) { }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      console.log(params);
      this.token = params.token;
      this.code = params.code;
    });
  }

  envoi() {
    this.erreur = null;
    if(this.password && this.password_confirmation) {
      if(this.password == this.password_confirmation) {
        this.authService.reinitMdp(this.token, this.code, this.password, this.password_confirmation).then((data) => {
          console.log(data);
          if(data['error']) {
            if(data['details'] && ((data['details']['token'] && data['details']['token'] == "Token invalide !") || data['details'] == "This password reset token is invalid.")) {
              this.erreur = "Lien expiré ! Veuillez refaire une demande.";
            } else {
              this.erreur = data['error'];
            }
          } else {
            this.router.navigate(['/connexion']);
          }
        }).catch((err) => {
          console.log(err);
          this.erreur = "Une erreur s'est produite.";
        });
      } else {
        this.erreur = "Les mots de passe ne correspondent pas";
      }
    } else {
      this.erreur = "Veuillez remplir tous les champs";
    }
  }

}
