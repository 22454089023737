import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-connexion-sso',
  templateUrl: './connexion-sso.component.html',
  styleUrls: ['./connexion-sso.component.scss']
})
export class ConnexionSsoComponent implements OnInit {

  invalide = false;

  constructor(private route: ActivatedRoute, private authService: AuthService, private router: Router) { }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      console.log(params);
      let token = params.token;
      if(token!='unauthorized' && token!='error') {
        localStorage.setItem('id_token_mesopeco', token);
        this.authService.getInfosUser().then((user) => {
          console.log(user['user'].reseau);
          if(user['user'].reseau && user['user'].reseau!='all') {
            localStorage.setItem('moc_reseau', user['user'].reseau);
            localStorage.setItem('moc_codeagence', user['user'].AGE_codeagence);
            localStorage.removeItem('all');
            this.router.navigate(['/recherche-perso']);
          } else {
            localStorage.setItem('all', 'true');
            this.router.navigate(['/choix-reseau']);
          }
        }).catch((err) => {
          console.log(err);
          this.invalide = true;
        });
      } else {
        if(token == "unauthorized")
          this.router.navigate(['/connexion', {acces: 'unauthorized'}]);
        else
          this.router.navigate(['/connexion', {acces: 'error'}]);
      }
    });
  }

}
