import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ModalModule } from 'ngx-bootstrap/modal';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ConnexionComponent } from './pages/connexion/connexion.component';
import { AccueilComponent } from './pages/accueil/accueil.component';
import { MonHeaderComponent } from './components/mon-header/mon-header.component';
import { RecherchePersoComponent } from './pages/recherche-perso/recherche-perso.component';
import { 
  FormsModule, 
  ReactiveFormsModule } from '@angular/forms';
  //import { HttpModule } from '@angular/http';
  import { HttpClientModule } from '@angular/common/http';
import { ListeOffresComponent } from './pages/liste-offres/liste-offres.component';
import { DetailOffreComponent } from './pages/detail-offre/detail-offre.component';
import { CalendrierComponent } from './pages/calendrier/calendrier.component';
import { FavorisComponent } from './pages/favoris/favoris.component';
import { JwtModule, JWT_OPTIONS } from "@auth0/angular-jwt";
import { ConnectComponent } from './pages/connect/connect.component';
import {DpDatePickerModule} from 'ng2-date-picker';
import { ChoixReseauComponent } from './pages/choix-reseau/choix-reseau.component';
import { MdpOublieComponent } from './pages/mdp-oublie/mdp-oublie.component';
import { InitMdpComponent } from './pages/init-mdp/init-mdp.component';
import { ConnexionSsoComponent } from './pages/connexion-sso/connexion-sso.component';
import { LoginActivateService } from './services/login-activate.service';

export function tokenGetter() {
  return localStorage.getItem("id_token_mesopeco");
}
export function jwtOptionsFactory() {
  return {
    tokenGetter: () => {
      return localStorage.getItem("id_token_mesopeco");
    },
    whitelistedDomains: ['https://mes-ope-co.dev.mediadev.info/']
  }
}
  
@NgModule({
  declarations: [
    AppComponent,
    ConnexionComponent,
    MdpOublieComponent,
    InitMdpComponent,
    ConnexionSsoComponent,
    ConnectComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    AppRoutingModule,
    DpDatePickerModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ModalModule.forRoot(),
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
      }
    }),
    /* JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        whitelistedDomains: ["https://mes-ope-co.dev.mediadev.info/api/"],
        //blacklistedRoutes: ["example.com/examplebadroute/"]
      }
    }) */
  ],
  providers: [
    LoginActivateService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
