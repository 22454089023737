import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GetJsonService {

  url;
  liste = [];
  filtres;
  tri = "offres";

  constructor(private http: HttpClient) {
    this.url = environment.urlAPI;
  }

  setListeOffres() {
    return new Promise((resolve, reject) => {
      let headers = new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem('id_token_mesopeco') });
      this.http.get(this.url + 'offre/lstOffre', { headers: headers })
        .subscribe((data) => {
          console.log(data);
         /*  data['offres'].forEach(element => {
            if (element.dispositif_reserve != 0) {
              console.log('Dispositif réservé', element);
            }
          }); */
          data['offres'].sort((a, b) => {
            if (a.ordre_cible < b.ordre_cible) {
              return -1;
            } else if (a.ordre_cible > b.ordre_cible) {
              return 1;
            } else {
              if (a.marche.ordre > b.marche.ordre) {
                return 1;
              } else if (a.marche.ordre < b.marche.ordre) {
                return -1;
              }
            }
            return 0;
          });
          this.liste = data['offres'];
          console.log('liste offres:::::::::', this.liste);
          this.filtrerOffres();
          this.trierOffres();
          resolve(data);
        }, (err) => {
          console.log(err);
          reject(err);
        });
    });
  }

  changeTri(tri) {
    this.tri = tri;
    this.trierOffres();
  }

  getFavoris(reload = false) {
    return new Promise((resolve, reject) => {
      console.log(this.liste);
      if (this.liste && this.liste.length > 0 && !reload) {
        let liste = this.liste.filter((offre) => {
          return offre.favori;
        });
        resolve(liste);
      } else {
        this.setListeOffres().then(() => {
          console.log(this.liste);
          let liste = this.liste.filter((offre) => {
            return offre.favori;
          });
          resolve(liste);
        });
      }
    });

  }

  setFiltres(filtres) {
    this.filtres = filtres;
  }

  getFiltres() {
    return this.filtres;
  }

  eraseFiltres() {
    this.filtres = null;
  }

  trierOffres() {
    switch (this.tri) {
      case 'date':
        this.liste.sort((a, b) => {
          if (a.date_debut < b.date_debut) {
            return -1;
          } else if (a.date_debut > b.date_debut) {
            return 1;
          } else if (a.ordre_cible < b.ordre_cible) {
            return -1;
          } else if (a.ordre_cible > b.ordre_cible) {
            return 1;
          } else if (a.marche.ordre < b.marche.ordre) {
            return -1;
          } else if (a.marche.ordre > b.marche.ordre) {
            return 1;
          }
          return 0;
        })
        break;
      case 'offres':
        this.liste.sort((a, b) => {
          if (a.type == "ponctuelle" && b.type != "ponctuelle") {
            return -1;
          } else if (a.type != "ponctuelle" && b.type == "ponctuelle") {
            return 1;
          } else if (a.ordre_cible < b.ordre_cible) {
            return -1;
          } else if (a.ordre_cible > b.ordre_cible) {
            return 1;
          } else if (a.marche.ordre < b.marche.ordre) {
            return -1;
          } else if (a.marche.ordre > b.marche.ordre) {
            return 1;
          }
          return 0;
        });
        //mettre les mois gratuits en premier
        this.liste.sort((a, b) => {
          if (a.mois_gratuit == 1 && b.mois_gratuit == 0) {
            return -1;
          } else if (a.mois_gratuit == 0 && b.mois_gratuit == 1) {
            return 1;
          }
          return 0;
        });
        console.log('liste triée', this.liste);
        break;
      case 'reserve':
        this.liste.sort((a, b) => {
          if (a.dispositif_reserve == 1 && (b.dispositif_reserve == 0 || b.dispositif_reserve == null)) {
            return -1;
          } else if ((a.dispositif_reserve == 0 || a.dispositif_reserve == null) && b.dispositif_reserve == 1) {
            return 1;
          } else if(a.mois_gratuit == 1 && b.mois_gratuit == 0) {
            return -1;
          } else if (a.mois_gratuit == 0 && b.mois_gratuit == 1) {
            return 1;
          } else if (a.ordre_cible < b.ordre_cible) {
            return -1;
          } else if (a.ordre_cible > b.ordre_cible) {
            return 1;
          } else if (a.marche.ordre < b.marche.ordre) {
            return -1;
          } else if (a.marche.ordre > b.marche.ordre) {
            return 1;
          }
          return 0;
        });
        console.log('liste triée', this.liste);
        break;
    }
    /* this.liste.forEach(element => {
      if (element.dispositif_reserve != 0) {
        console.log('Dispositif réservé', element);
      }
    }); */

    //mettre les offres ponctuelles toujours en haut
    /* this.liste.sort((a, b) => {
      if (a.type == "ponctuelle" && b.type != "ponctuelle") {
        return -1;
      } else if (a.type != "ponctuelle" && b.type == "ponctuelle") {
        return 1;
      } else if (a.ordre_cible < b.ordre_cible) {
        return -1;
      } else if (a.ordre_cible > b.ordre_cible) {
        return 1;
      } else if (a.marche.ordre < b.marche.ordre) {
        return -1;
      } else if(a.marche.ordre > b.marche.ordre) {
        return 1;
      }
      return 0;
    }); */

  }

  filtrerOffres() {
    this.liste = this.liste.filter((offre) => {
      //console.log(offre.type);
      return offre.dates && offre.dates.length > 0;
    });
    let reseau = localStorage.getItem('moc_reseau');
    if (reseau == 'agent'/*  || reseau == 'outremer' */) {
      this.liste = this.liste.filter((offre) => {
        return offre.agent == 1 && (offre.codes_agence == null || offre.codes_agence.includes(localStorage.getItem('moc_codeagence')));
      });
    } else if(reseau == 'outremer') {
      this.liste = this.liste.filter((offre) => offre.outremer == 1);
    } else if (reseau == 'courtage') {
      this.liste = this.liste.filter((offre) => offre.courtage == 1);
    } else if (reseau == "aec") {
      this.liste = this.liste.filter((offre) => offre.aec == 1)
    }
    console.log("liste filtrée", this.liste);
    //console.log(this.liste);
    //n'afficher que les offres en cours
    let today = new Date();
    this.liste.filter((offre) => {
      let found = offre.dates.find((date) => {
        let tabfinstr = date.date_fin.split('/');
        let finstr = tabfinstr[2] + '-' + tabfinstr[1] + '-' + tabfinstr[0];
        let fin = new Date(finstr);
        return fin.getTime() >= today.getTime();
      });
      if (found) {
        return true;
      }
      return false;
    });

    //appliquer les filtres
    console.log("filtres", this.filtres);
    if (this.filtres) {
      //filtrer par cible
      if (this.filtres.cibles && this.filtres.cibles.length > 0) {
        this.liste = this.liste.filter((offre) => {
          return this.filtres.cibles.indexOf(offre.cible_id) !== -1;
        });
      }
      /* if (this.filtres.cibles && this.filtres.cibles.length == 1 && this.filtres.cibles[0] == 'specifique' && this.filtres.codeNAF) {
        this.liste = this.liste.filter((offre) => {
          return this.filtres.codeNAF == offre.code_naf;
        })
      } */
      //filtrer par marches
      if (this.filtres.marches && this.filtres.marches.length > 0 && !this.filtres.tous_marches) {
        this.liste = this.liste.filter((offre) => {
          return this.filtres.marches.indexOf(offre.marche_id) !== -1;
        });
      }
      //filtrer par dates
      if (this.filtres.date_debut) {
        this.liste = this.liste.filter((offre) => {
          let found = offre.dates.find(date => {
            let tabdebutstr = date.date_debut.split('/');
            let debutstr = tabdebutstr[2] + '-' + tabdebutstr[1] + '-' + tabdebutstr[0];
            return debutstr <= this.filtres.date_debut;
          });
          return found ? true : false;
        });
      }
      if (this.filtres.date_fin) {
        this.liste = this.liste.filter((offre) => {
          let found = offre.dates.find(date => {
            let tabfinstr = date.date_fin.split('/');
            let finstr = tabfinstr[2] + '-' + tabfinstr[1] + '-' + tabfinstr[0];
            return finstr >= this.filtres.date_fin;
          });
          return found ? true : false;
        });
      }
    }
  }

  getListeOffres() {
    return this.liste;
  }

  getOffreFromListe(id) {
    return this.liste.find((offre) => offre.id == id);
  }

  addFavori(id_offre) {
    let headers = new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem('id_token_mesopeco') });
    return new Promise((resolve, reject) => {
      let token = localStorage.getItem('id_token_mesopeco');
      this.http.post(this.url + 'offre/addFavori', { id_offre: id_offre }, { headers: headers })
        .subscribe((data) => {
          console.log(data);
          this.setListeOffres().then(() => {
            resolve(data);
          });
        }, (err) => {
          console.log(err);
          reject(err);
        });
    });
  }

  suppFavori(id_offre) {
    let headers = new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem('id_token_mesopeco') });
    return new Promise((resolve, reject) => {
      let token = localStorage.getItem('id_token_mesopeco');
      this.http.post(this.url + 'offre/removeFavori', { id_offre: id_offre }, { headers: headers })
        .subscribe((data) => {
          console.log(data);
          resolve(data);
        }, (err) => {
          console.log(err);
          reject(err);
        });
    });
  }

  getProfil() {
    let headers = new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem('id_token_mesopeco') });
    return new Promise((resolve, reject) => {
      let token = localStorage.getItem('id_token_mesopeco');
      this.http.get(this.url + 'profil', { headers: headers })
        .subscribe((data) => {
          console.log(data);
          resolve(data);
        }, (err) => {
          console.log(err);
          reject(err);
        });
    });
  }

  getReseaux() {
    let headers = new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem('id_token_mesopeco') });
    return new Promise((resolve, reject) => {
      let token = localStorage.getItem('id_token_mesopeco');
      this.http.get(this.url + 'structure/getReseaux', { headers: headers })
        .subscribe((data) => {
          console.log(data);
          resolve(data);
        }, (err) => {
          console.log(err);
          reject(err);
        });
    });
  }

  getMarches() {
    let headers = new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem('id_token_mesopeco') });
    return new Promise((resolve, reject) => {
      let token = localStorage.getItem('id_token_mesopeco');
      this.http.get(this.url + 'structure/getMarches', { headers: headers })
        .subscribe((data) => {
          console.log(data);
          resolve(data);
        }, (err) => {
          console.log(err);
          reject(err);
        });
    });
  }

  getActus() {
    let headers = new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem('id_token_mesopeco') });
    return new Promise((resolve, reject) => {
      //let token = localStorage.getItem('id_token_mesopeco');
      this.http.get(this.url + 'actu/lstActu', { headers: headers })
        .subscribe((data) => {
          console.log(data);
          resolve(data);
        }, (err) => {
          console.log(err);
          reject(err);
        });
    });
  }

  envoiStatFiche(id) {
    let headers = new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem('id_token_mesopeco') });
    return new Promise((resolve, reject) => {
      this.http.get(this.url + 'offre/show/' + id, { headers: headers })
        .subscribe((data) => {
          console.log(data);
          resolve(data);
        }, (err) => {
          console.log(err);
          reject(err);
        });
    });
  }
}
