import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  url;

  constructor(private http: HttpClient, private jwtHelper: JwtHelperService) { 
    this.url = environment.urlAPI;
  }

  login(code, password) {
    return new Promise((resolve, reject) => {
      this.http.post(this.url + 'login', {code : code, password : password})
      .subscribe((data) => {
        console.log(data);
        localStorage.setItem('id_token_mesopeco', data['token']);
        resolve(data);
      }, (err) => {
        console.log(err);
        reject(err);
      });
    })
  }

  mdpOublie(code) {
    return new Promise((resolve, reject) => {
      this.http.post(this.url + 'user/password/email', {code : code})
      .subscribe((data) => {
        console.log(data);
        resolve(data);
      }, (err) => {
        console.log(err);
        reject(err);
      });
    })
  }

  reinitMdp(token, code, password, password_confirmation) {
    return new Promise((resolve, reject) => {
      this.http.post(this.url + 'user/password/submit/reset', {token: token, code : code, password: password, password_confirmation: password_confirmation})
      .subscribe((data) => {
        console.log(data);
        resolve(data);
      }, (err) => {
        console.log(err);
        reject(err);
      });
    })
  }

  getInfosUser() {
    console.log('tokennnnnnnnnnn', localStorage.getItem('id_token_mesopeco'));
    let headers = new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem('id_token_mesopeco') });
    return new Promise((resolve, reject) => {
      this.http.get(this.url + 'profil', { headers: headers })
        .subscribe((data) => {
          console.log(data);
            resolve(data);
        }, (err) => {
          console.log(err);
          reject(err);
        });
    });
  }

  loggedIn() {
    let token = localStorage.getItem('id_token_mesopeco');
      return !this.jwtHelper.isTokenExpired(token);
  }

  public logout() {
    return Observable.create(observer => {
      localStorage.removeItem('id_token_mesopeco');
      localStorage.clear();
      observer.next(true);
      observer.complete();
    });
  }
}
