import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-mdp-oublie',
  templateUrl: './mdp-oublie.component.html',
  styleUrls: ['./mdp-oublie.component.scss']
})
export class MdpOublieComponent implements OnInit {

  code;
  erreur;
  envoye = false;
  message;

  constructor(private router: Router, private authService: AuthService) { }

  ngOnInit() {
  }

  connexion() {
    this.router.navigate(['/connexion']);
  }

  envoi() {
    this.erreur = null;
    if(this.code) {
      this.authService.mdpOublie(this.code).then((data) => {
        console.log(data);
        if(data['error']) {
          this.erreur = data['details'].code[0];
        } else {
          this.envoye = true;
          if(data['data'])
            this.message = data['data'].message;
          if(!this.message) {
            this.message = "Nous avons envoyé par e-mail votre lien de réinitialisation de mot de passe !";
          }
        }
      }).catch((err) => {
        console.log(err);
      });
    } else {
      this.erreur = "Veuillez renseigner un DICID ou un code agence";
    }
  }

  retour() {
    this.router.navigate(['/connexion']);
  }

}
