import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { GetJsonService } from '../../services/get-json.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-connexion',
  templateUrl: './connexion.component.html',
  styleUrls: ['./connexion.component.scss']
})
export class ConnexionComponent implements OnInit {

  code;
  password;
  erreur;
  unauthorized=false;
  error = false;
  urlSSO;

  constructor(private route: ActivatedRoute, private router: Router, private authService: AuthService, private jsonService: GetJsonService) { }

  ngOnInit() {
    this.urlSSO = environment.urlAPI.replace('/api', '');
    //verifier si on est connecté
    /* this.jsonService.getReseaux().then((data) => {
      console.log(data);
      this.router.navigate(['/recherche-perso'])
    }).catch((err) => {
      console.log(err);
    }); */
    this.route.params.subscribe((params) => {
      console.log(params);
      if(params.acces == 'unauthorized') {
        this.unauthorized = true;
      } else if(params.acces == 'error') {
        this.error = true;
      }
    })
  }

  connexion() {
    console.log('connexion');
    this.authService.login(this.code, this.password).then((data) => {
      //console.log(data['token']);
      this.authService.getInfosUser().then((user) => {
        console.log(user['user'].reseau);
        if(user['user'].reseau && user['user'].reseau!='all') {
          localStorage.setItem('moc_reseau', user['user'].reseau);
          localStorage.setItem('moc_codeagence', user['user'].AGE_codeagence);
          localStorage.removeItem('all');
          this.router.navigate(['/recherche-perso']);
        } else {
          localStorage.setItem('all', 'true');
          this.router.navigate(['/choix-reseau']);
        }
      });
      //this.router.navigate(['/recherche-perso']);
    }).catch((err) => {
      console.log('statut erreur', err['status']);
      /* if(err['status']==401) {
        //redirection https://mes-ope-co.preprod.mediadev.info/saml2/allianz/login
        window.open('https://mes-ope-co.preprod.mediadev.info/saml2/allianz/login', '_system')
      } else */
        this.erreur = "Code ou mot de passe incorrect";
    })
    //this.router.navigate(['/accueil']);
  }

  mdpOublie() {
    this.router.navigate(['/mdp-oublie']);
  }

}
