import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-connect',
  templateUrl: './connect.component.html',
  styleUrls: ['./connect.component.scss']
})
export class ConnectComponent implements OnInit {

  urlSSO;

  constructor() { }

  ngOnInit() {
    this.urlSSO = environment.urlAPI.replace('/api', '');
    window.open(this.urlSSO+'saml2/allianz/login', '_self');
  }

}
